export class LoginData
{
    Email:string;
    PartitaIva:string;
    Token:string;
    constructor(Email:string, PartitaIva:string, Token:string){
        this.Email = Email;
        this.PartitaIva = PartitaIva;
        this.Token = Token;
    }
}