import xml2js from 'xml2js';
import { Server } from 'selenium-webdriver/safari';
export class Valori
{
    Id:number;
    Nome:string;
    Caption: string;
    SqlCommand: string;
    Tipo: string;
    Lunghezza: number;
    Elenco_Valori: Array<string> = new Array<string>();
    Elenco_Default:Array<number>= new Array<number>();
    
    constructor(Id:number,Nome:string,xml:string, Caption:string,Tipo:string,Lunghezza:number)
    {
        this.Id = Id;
        this.Nome = Nome;
        this.Caption = Caption;
        this.Tipo = Tipo;
        this.Lunghezza = Lunghezza;
        if (xml != null && xml != ""){
            xml2js.parseString(xml,{explicitArray:false},(error,result)=>{
                if (error)
                    alert("Errore parsing XML: " + error);
                else{
                    var resultRows = result.rows.row;
                    //alert(resultRows.length);
                    for (var i = 0; i< resultRows.length; i++)
                    {
                        //alert(resultRows[i].$.value);
                        this.Elenco_Valori.push(resultRows[i].$.value);
                        this.Elenco_Default.push(resultRows[i].$.default);
                    }
                }
            });
        }

    }
}