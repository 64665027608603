import { LoginData } from './../model/logindata.model';
import { Cliente } from './../model/cliente.model';
import { DataReaderService } from './../service/data-reader.service';
import { Component, OnInit, Output, EventEmitter} from '@angular/core';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  clienteLoggato:Cliente;
  datiLogin: LoginData;
  errorMsg:string = "";
  email:string;
  partitaiva:string;
  _status: boolean;
  
  @Output() status = new EventEmitter<Boolean>();
  @Output() dati_login = new EventEmitter<LoginData>();
  @Output() dati_cliente = new EventEmitter<Cliente>();
  constructor(private drs:DataReaderService) { }
  ngOnInit() {
    this.email = "";
    this.partitaiva = "";
    this._status = false;
  }
  setDatiCliente(cli)
  {
    this.clienteLoggato = cli;
    this.dati_cliente.emit(this.clienteLoggato);
  }
  setDatiLogin(logData)
  {
    this.datiLogin = logData;
    this.dati_login.emit(this.datiLogin);
    
  }
  setStatus(newStatus)
  {
    this._status = newStatus;
    this.status.emit(this._status);
  }
  _validate(){
      let r  = this.drs.checkLoginSync(this.email,this.partitaiva);
      r.then(esito => { 
        let e = esito['data'];
        if (e['type'] && e['type'] == 'error')
          alert(e['data']);
        else
        {  
        this.setDatiCliente(e as Cliente);
        localStorage.setItem("cli",JSON.stringify(this.clienteLoggato)); 
        this.errorMsg = "";
        this.setDatiLogin(new LoginData(this.email,this.partitaiva,this.clienteLoggato.token));
        localStorage.setItem("log",JSON.stringify(this.datiLogin))
        this.setStatus(true);}
      });
      r.catch(msg => {
         alert(msg.message)
         this.setStatus(false);
         this.setDatiLogin(null); 
         this.setDatiCliente(null);
        });
  }

}
