import { DataReaderService } from './service/data-reader.service';
import { Component, OnInit } from '@angular/core';
import { LoginData } from './model/logindata.model';
import { Cliente } from './model/cliente.model';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'ticket';
  status = false;
  logData: LoginData = null;
  cliente:Cliente = null;
  busy:Boolean = false;
  esito: Boolean = false;
  
  ditta:string = "";
  logo:string = "";
  messaggio_benvenuto:string = "";
  server:string = "";
  
  constructor(private drs:DataReaderService){}
  checkStatus(newStatus:boolean)
  {
    this.status = newStatus;
  }
  checkLoginData(newData:LoginData){ this.logData = newData}
  checkClienteData(newData:Cliente){ this.cliente = newData}
  checkStatoInvio(newInvio:Boolean){this.busy = newInvio}
  checkEsito(newEsito:Boolean){this.esito = newEsito;}
  private checkSessionCli()
  {
    let result = localStorage.getItem("cli");
      if (result){
        let cliente =  JSON.parse(result) as Cliente;
        if (cliente)
          return cliente;
      }
      return null;
  }
  private checkSessionLog()
  {
    let result = localStorage.getItem("log");
      if (result){
        let login =  JSON.parse(result) as LoginData;
        if (login)
          return login;
      }
      return null;
  }
  private initSession()
  {
      let c = this.checkSessionCli();
      let l = this.checkSessionLog();
      if (l && c)
      {
        let prom = this.drs.isValidToken(l.Token);
        prom.then(esito => 
        { 
          let e = esito['data'];
          if (e['type'] && e['data'] == 'VALID_TOKEN')
          {
            this.logData = l;
            this.cliente = c;
            this.status = true;
          }         
        });  
      }
  }
  ngOnInit()
  { 
    this.drs.getIniData().then(esito => {
      if (esito['ditta'])
        this.ditta = esito['ditta'];
      if (esito['messaggio_benvenuto'])  
        this.messaggio_benvenuto = esito['messaggio_benvenuto'];
      if (esito['logo'])
        this.logo = esito['logo'];  
      if (esito['server'])
        this.server = esito['server'];
      if (this.server && this.server != "")
      {
        this.drs.setServer(this.server);
        this.initSession();
      }
    });
  }
}
