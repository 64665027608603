import { NgForm } from '@angular/forms';
import { HttpClient,HttpHeaders,HttpErrorResponse, HttpParams } from '@angular/common/http';
import {Cliente} from './../model/cliente.model';
import {LoginData} from './../model/logindata.model';

import { Injectable, Input } from '@angular/core';
import {Observable} from 'rxjs';
import {map,filter,catchError,mergeMap} from 'rxjs/operators';
import * as crypto from 'crypto-js';

@Injectable(/*{
  providedIn: 'root'
}*/)
export class DataReaderService {
  
  //private apiUrl:string = "http://localhost:53384/service.ashx";
  private apiUrl:string = "service.ashx";
  private apiMail = "http://arcaticket.wkp30.it/sm.php";
  private secretKey:string = "H3P4JWRV7NKNM2";
  constructor(private http:HttpClient) { 
    
  }
  public setServer(server:string)
  {
    this.apiUrl = server + "/" + this.apiUrl;
      
  }
  public async getIP() 
  {
    let promise = await this.http.get("./sip.keyip").toPromise();
    return promise;
  }
  public async getIniData()
  {
    let promise = await this.http.get("./datas.ini").toPromise();
    return promise;
  }
  public checkLogin(mail:string,piva:string):Observable<Cliente>
  {
    
    let b64h = btoa(JSON.stringify({"alg":"HS256","typ":"JWT"})).replace(/=/g, '').replace(/\//g,'_').replace(/\+/g,'-');
    let b64p = btoa(JSON.stringify({email: mail, partitaiva:piva})).replace(/=/g, '').replace(/\//g,'_').replace(/\+/g,'-');
    let hash = crypto.HmacSHA256(b64h + '.' + b64p, this.secretKey);
    let hash64 = crypto.enc.Base64.stringify(hash).replace(/=/g, '').replace(/\//g,'_').replace(/\+/g,'-');
    let token64 = b64h + '.' + b64p + '.' + hash64;

    let hdr = new HttpHeaders({'METHOD':'login'});
    return this.http.post(this.apiUrl,JSON.stringify({token: token64}),{headers: hdr}).pipe(
      map(esito=>esito['cf'] as Cliente),
      catchError(this.handleErrorObjs)
      );
  }
  public async checkLoginSync(mail:string,piva:string)
  {
    let b64h = btoa(JSON.stringify({"alg":"HS256","typ":"JWT"})).replace(/=/g, '').replace(/\//g,'_').replace(/\+/g,'-');
    let b64p = btoa(JSON.stringify({email: mail, partitaiva:piva})).replace(/=/g, '').replace(/\//g,'_').replace(/\+/g,'-');
    let hash = crypto.HmacSHA256(b64h + '.' + b64p,this.secretKey);
    let hash64 = crypto.enc.Base64.stringify(hash).replace(/=/g, '').replace(/\//g,'_').replace(/\+/g,'-');
    let token64 = b64h + '.' + b64p + '.' + hash64;
    let hdr = new HttpHeaders({'METHOD':'login'});
    //return this.http.post(this.apiUrl,JSON.stringify({token: token64}),{headers: hdr}).toPromise()
    //  .then(esito=>esito['cf'] as Cliente).catch((msg:Response) => {alert(msg.status)});
    let promise = await this.http.post(this.apiUrl,JSON.stringify({token: token64}),{headers: hdr}).toPromise();
    return promise;  
  }
  public async getActivitySync(token:string)
  {
    let b64h = btoa(JSON.stringify({"alg":"HS256","typ":"JWT"})).replace(/=/g, '').replace(/\//g,'_').replace(/\+/g,'-');
    let b64p = btoa(JSON.stringify({token: token})).replace(/=/g, '').replace(/\//g,'_').replace(/\+/g,'-');
    let hash = crypto.HmacSHA256(b64h + '.' + b64p,this.secretKey);
    let hash64 = crypto.enc.Base64.stringify(hash).replace(/=/g, '').replace(/\//g,'_').replace(/\+/g,'-');
    let token64 = b64h + '.' + b64p + '.' + hash64;
    let hdr = new HttpHeaders({'METHOD':'activity'});
    
    //return this.http.post(this.apiUrl,JSON.stringify({token: token64}),{headers: hdr}).toPromise()
    //  .then(esito=>esito['cf'] as Cliente).catch((msg:Response) => {alert(msg.status)});
    let promise = await this.http.post(this.apiUrl,JSON.stringify({token: token64}),{headers: hdr}).toPromise();
    return promise;      
  }
  public async sendMail(cliente:Cliente, datiLogin:LoginData, mail:string)
  {
    let data = new HttpParams();
    
    data = data.set("addr_from", datiLogin.Email); 
    data = data.set("cfdescription", cliente.descrizione);
    data = data.set("token", cliente.token);
    data = data.set("cf",cliente.cd_cf);
    data = data.set("addr_to",mail);

        let hdr = new HttpHeaders({ 'Content-Type':  'application/x-www-form-urlencoded;charset=utf-8'});
        let promise = await this.http.post(this.apiMail,data,{headers:hdr}).toPromise();
        return promise;
  }
  public async getMailSupport()
  {
    let data = {};
    let hdr = new HttpHeaders({'METHOD':'support_mail'});
    let promise = await this.http.post(this.apiUrl,JSON.stringify(data),{headers: hdr}).toPromise();
    return promise;
  }
  public async sendSupportRequest(Form:NgForm, cliente:Cliente)
  {
    //let h = crypto.enc.Utf8.parse(JSON.stringify({"alg":"HS256","typ":"JWT"}));
    let h = btoa(JSON.stringify({"alg":"HS256","typ":"JWT"}));
    //let b64h = crypto.enc.Base64.stringify(h).replace(/=/g, '').replace(/\//g,'_').replace(/\+/g,'-');
    let b64h = h.replace(/=/g, '').replace(/\//g,'_').replace(/\+/g,'-');
       
    let data = {token: cliente.token, cd_cf: cliente.cd_cf, id_contatto:cliente.id_contatto,fields:{}};
    let hdr = new HttpHeaders({'METHOD':'insert_ticket'});
    for (let f in Form.controls)
    {
      data["fields"][f] = encodeURIComponent(Form.controls[f].value);
    }
    
    //let p = crypto.enc.Utf8.parse(JSON.stringify(data));
    //let b64p = crypto.enc.Base64.stringify(p).replace(/=/g, '').replace(/\//g,'_').replace(/\+/g,'-');
    let p = btoa(JSON.stringify(data));
    let b64p = p.replace(/=/g, '').replace(/\//g,'_').replace(/\+/g,'-');
    
    let hash = crypto.HmacSHA256(b64h + '.' + b64p,this.secretKey);
    let hash64 = crypto.enc.Base64.stringify(hash).replace(/=/g, '').replace(/\//g,'_').replace(/\+/g,'-');
    let token64 = b64h + '.' + b64p + '.' + hash64;
    
    let promise = await this.http.post(this.apiUrl,JSON.stringify({token:token64}),{headers: hdr}).toPromise();
    return promise;
  }
  public async isValidToken(xToken:string)
  {
    let b64h = btoa(JSON.stringify({"alg":"HS256","typ":"JWT"})).replace(/=/g, '').replace(/\//g,'_').replace(/\+/g,'-');
       
    let hdr = new HttpHeaders({'METHOD':'check_token'});
    let data = {token:xToken};
    
    let promise = await this.http.post(this.apiUrl,JSON.stringify(data),{headers: hdr}).toPromise();
    return promise;
  }  
  private handleErrorObjs(error:any)
  {
    return Observable.throw(error.message || error);
  }
}
