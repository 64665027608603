import { Valori } from './../model/valori.model';
import { Cliente } from './../model/cliente.model';
import { DataReaderService } from './../service/data-reader.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoginData } from '../model/logindata.model';
import { Attivita } from '../model/attivita.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-assistenza',
  templateUrl: './assistenza.component.html',
  styleUrls: ['./assistenza.component.css']
})
export class AssistenzaComponent implements OnInit {

  @Input() loginData: LoginData;
  @Output() esito_invio = new EventEmitter<Boolean>();
  @Output() busy = new EventEmitter<Boolean>();
  
  attivita:Attivita;
  valori: Array<Valori> = new Array<Valori>();
  _esito:Boolean = false;
  _cliente: Cliente;
  _login: LoginData;
  _busy:Boolean = false;
  
  constructor(private drs:DataReaderService) { }
  
  get cliente(): Cliente{
    return this._cliente;
  }
  
  @Input()
  set cliente(cliente: Cliente)
  {
    this._cliente = cliente;
    
    this.tryToCreate();
  }
 
  get login(): LoginData{
    return this._login;
  }
  
  @Input()
  set login(login: LoginData)
  {
    this._login = login;
    this.tryToCreate();
  }
  private tryToCreate(){
    this.attivita = null;
    if (!this._login) return;
    let promise = this.drs.getActivitySync(this._login.Token);
    promise.then(esito => 
      {
        let e = esito['data'];
        if (e['type'] && e['type'] == 'error')
          alert(e['data']);
        else
        {  
          
        this.attivita = e as Attivita;
        for (var i = 0; i< this.attivita.ExtraInfo.length; i++){
          this.valori.push (new Valori(
            this.attivita.ExtraInfo[i].Id,
            this.attivita.ExtraInfo[i].Descrizione,
            this.attivita.ExtraInfo[i].Valori, 
            this.attivita.ExtraInfo[i].DescrizioneLunga,
            this.attivita.ExtraInfo[i].Tipo,
            this.attivita.ExtraInfo[i].Lunghezza
          ));
          //alert(this.attivita.ExtraInfo[i].Tipo);
        }
    }});
    promise.catch(msg => {
      alert(msg.message);
    });
  }
  setBusy(newBusy)
  {
    this._busy = newBusy;
    this.busy.emit(this._busy);
  }  
  setEsito(newEsito)
  {
    this._esito = newEsito;
    this.esito_invio.emit(this._esito);
  }
  ngOnInit() {

  }
  inviaRichiesta(Form:NgForm){
    if (!Form.form.valid)
    {
        alert("Compilare tutti i campi del form di assistenza!");
        return;
    }
    this.setBusy(true); 
    
    //alert(this._login.Email);this.setBusy(false);
    let p = this.drs.sendSupportRequest(Form,this.cliente);
    p.then(esito => {
      let result = esito['data'];
        if (result['type'] && result['type'] == "success")
        {
          
          alert("Inserimento segnalazione completato correttamente.\r\n Sarai contattato al più presto dal nostro staff!");
          let p = this.drs.getMailSupport();
          p.then(esito2=>{
            ;
            let mail = esito2['support_mail'];
            if (mail && mail !='')
            {    
              let m = this.drs.sendMail(this.cliente, this._login,mail);
              m.then(esito => {});
              this.setEsito(true);
            }
          });
        }
        else{
          alert("Inserimento segnalazione non riuscito! Contatta l'amministratore del sito web per richiedere informazioni\r\n" + result['data']);
        }      
        this.setBusy(false);
      });
    p.catch(msg => {alert("Errore:" + msg.message );this.setBusy(false);});  
    
  }

}
