export class Cliente
{
    token:string;
    cd_cf:string;
    descrizione:string;
    id_contatto:number;
    constructor(cd_cf:string,descrizione:string,id_contatto:number, token:string)
    {
        this.cd_cf = cd_cf;
        this.descrizione = descrizione;
        this.id_contatto = id_contatto;
        this.token = token;
    }    
}
